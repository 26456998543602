
.custom-toast-container {
  position: absolute;
  top:calc(env(safe-area-inset-top) + 40px);
  right: 0px;
  height: 95%;
  overflow: auto;
  width: 320px;
  z-index: 10;

  @keyframes fade_in_show {
    0% {
      opacity: 1;
      transform: scale(1)
    }

    100% {
      opacity: 0;
      transform: scale(0)
    }
  }

  .custom-toast-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column-reverse;
  }

  .custom-toast {
    background: white;
    color:#000;
    margin: 10px;
    min-height: 60px;
    max-width: 300px;
    min-width: 280px;
    width: 300px;
    border-radius: 5px;
    //box-shadow: 4px 2px 5px grey;
    box-shadow: 0px 0px 3px grey;

    .custom-toast-header {
      background: lightgrey;
      height: 30px;
      position: relative;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;

      .custom-toast-header-img {
        display: inline-block;
        height: 30px;
        width: 30px;
        border-top-left-radius: 5px;
      }

      .custom-toast-header-title {
        position: absolute;
        top: 5px;
        left: 35px;
        font-size: 0.9rem;
      }

      .custom-toast-header-addition {
        position: absolute;
        top: 5px;
        right: 25px;
        font-size: 0.7rem;
      }

      .custom-toast-header-close {
        position: absolute;
        top: 2px;
        right: 0;
        background: lightgrey;
        border-top-right-radius: 5px;

        span {
          background: lightgrey;
          font-size: 16px;
          color:#000;
        }
      }
    }

    .custom-toast-body {
      padding: 5px 10px;
      font-size: 1.1rem;

      .custom-toast-body-title {
        font-weight: bold;
      }

      .custom-toast-body-content {
        img {
          max-width: 100%;
        }
      }

    }
  }
}
